import { useEffect } from "react"

const QSOnBoardForm = () => {
    useEffect(() => {
        window.location.href = "https://share.hsforms.com/1vJuS6rI-RriBJiZowrjjCgd8664"
    },[])
    return(
        <h3 className="text-center my-5">Redirecting to order request form...</h3>
    )
}

export default QSOnBoardForm