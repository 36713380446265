import './App.css';

// React
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js'

// Components
import Navbar from './features/navbar';
import Home from './pages/Home';
import Footer from './features/footer';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import AgeAuthentication from './pages/AgeAuthentication';
import AgeVerification from './features/ageverification';
import ScrollToTop from './features/scrolltotop';
import HelpForm from './pages/HelpForm';
import OnBoardForm from './pages/OnBoardForm';
import ReOrderForm from './pages/ReOrderForm';
import ComingSoon from './pages/ComingSoon';
import PrivacyPolicy from './pages/privacypolicy';
import QSOnBoardForm from './pages/QSOnBoardForm';

function App() {
  const [verified, setVerified] = useState(false)

  useEffect(() => {
    const data = window.localStorage.getItem("AGE_VERIFICATION")
    if (data !==  null) {
        setVerified(data)
    }

  }, [])

  useEffect(() => {
      window.localStorage.setItem("AGE_VERIFICATION", verified)
  }, [verified])

  return (
    <Router>
      {verified ? <Navbar /> : null}
      <ScrollToTop />
      <Routes>
        <Route element={<AgeVerification verified={verified} setVerified={setVerified} />} >
          <Route path="/"  element={<Home />}/>
          <Route path="/services"  element={<Services />}/>
          <Route path="/about"  element={<About />}/>
          <Route path="/contact"  element={<Contact />}/>
          <Route  path="/help" element={ <HelpForm /> } />
          <Route  path="/reorder" element={ <ReOrderForm /> } />
          <Route  path="/onboard" element={ <OnBoardForm /> } />
          <Route  path="/privacypolicy" element={ <PrivacyPolicy /> } />
          <Route  path="/qsonboard" element={ <QSOnBoardForm /> } />
        </Route>
      </Routes>
      {verified ? <Footer /> : null}
    </Router>
  );
}

export default App;
